import { render, staticRenderFns } from "./LandingPage.vue?vue&type=template&id=5453e764&scoped=true&lang=pug&"
import script from "./LandingPage.vue?vue&type=script&lang=js&"
export * from "./LandingPage.vue?vue&type=script&lang=js&"
import style1 from "./LandingPage.vue?vue&type=style&index=1&id=5453e764&scoped=true&lang=scss&"
import style2 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5453e764",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QResponsive,QImg,QVideo});
